export interface ReconcileParams {
    reconcileId: string;
    shopId: string;
    offset: number;
    limit: number;
}

export interface InsertReconcilePayload {
    courierId: string;
    reconciles: Array<{ reference: string; }>;
}

export enum RECONCILE_STATUS {
    RECONCILED = 'RECONCILED',
    COMPLETED = 'COMPLETED',
}

export interface IReconcileTransfer {
    reconcileId: string;
    shops: string[];
}
export interface InsertCodHold{
    startDate: string;
    endDate: string;
}
export interface CodHoldDetail{
    startDate: string;
    endDate: string;
    shopId: string;
}

export enum HOLDING_TYPE {
    OVERDUE_BILL = 'OVERDUE_BILL',
    NEGATIVE_CREDIT_BALANCE = 'NEGATIVE_CREDIT_BALANCE',
}

export type KeyHoldCodMap = {
	name: string
}

export type KeyHOLDTypeMap = {
	[key in HOLDING_TYPE]: KeyHoldCodMap
}

export const holdCodTypeMap: KeyHOLDTypeMap = {
	[HOLDING_TYPE.OVERDUE_BILL]: {
		name: 'ค้างชำระบิล',
	},
	[HOLDING_TYPE.NEGATIVE_CREDIT_BALANCE]: {
		name: 'ยอดเครดิต ',
	},
}

export enum LOG_RECONCILE_STATUS {
    PENDING = 'PENDING',
    PROCESS = 'PROCESS',
    COMPLETED = 'COMPLETED',
    RECONCILED = 'RECONCILED',
    GENERATED = 'GENERATED',
  }

export interface NotReceiveParams {
    offset: number
    limit: number
    logReconcileId: string
} 

export interface LogReconcilePreviewPayload {
    offset: number
    limit: number
    logReconcileId: string
}